<template>
  <div id="app">
    <h1>Clockify Counter • DSML</h1>
    <form v-on:submit.prevent='submitKey'>
      <input class='apikey_input' type='text' required v-model='apiKey' placeholder="Clockify API key">
      <br>
      <input class='apikey_input' type='text' required v-model='flowluApiKey' placeholder="Flowlu API key">
      <br>
      <input type='submit' value='Далее'>
    </form>
    <div class='clearlink' v-on:click='clearApiKey' v-if='apiKey'>Clear API key</div>

    <ul class='workspaces__items' v-if='workspaces.length > 0'>
      <li v-for="workspace in workspaces" v-on:click='selectedWorkspace=workspace' class='workspaces__item' :class='{selected: workspace == selectedWorkspace}'>
        <img :src="workspace.imageUrl" v-if='workspace.imageUrl' :alt='workspace.name'>
        <span class='workspaces__item-title'>{{ workspace.name }}</span>
      </li>
    </ul>

    <form class="" v-if='selectedWorkspace'>
      С
      <input type='date' v-model='startDate' :disabled='loading' v-on:blur='changeRange'>
       (вкл.)
      по
      <input type='date' v-model='endDate' :disabled='loading' v-on:blur='changeRange'>
       (искл.)
    </form>

    <ul class='clients-list' v-if='Object.keys(clients).length && !loading'>
      <li
        class='clients-list__item'
        :class='{ active: selectedClientId == clientId }'
        v-for='(clientName, clientId) in clients'
        v-on:click.exact='selectClient(clientId)'>
        {{ clientName }}
      </li>
    </ul>

    <ul class='projects-list' v-if='projectIds.length && !loading'>
      <li
        class='projects-list__item'
        :class='{ active: activeProjects.indexOf(projectId) > -1 }'
        v-for='projectId in orderedProjectIds'
        v-on:click.exact='selectProject(projectId)'
        v-on:click.meta='selectProjects(projectId)'>
        {{projects[projectId].name}} <span v-if='projects[projectId].client' :style="{'font-weight': 'bold'}">&nbsp;({{ projects[projectId].client.name }})</span>
        <span class='dot' :style="{ background: projects[projectId].color }"></span>
      </li>
    </ul>

    <div class='loading' v-if='loading'>
      <img src='./assets/loading.gif'>
    </div>

    <div class="distribution__wrapper">
      <table class='distribution' v-if='activeProjects.length > 0 && !loading'>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><strong>{{ totalTime | toHMS }}</strong></strong></td>
            <td></td>
            <td><input v-model='earnedSum' type='number' v-if='totalTime > 0'>
                <input v-model='percent' type='number' min='0' max='100' step='1' title='Bonus %' v-if='totalTime > 0'></td>
            <td>
              <!-- <input v-model='hourlyRate' type='number' min='0' step='1' title='Hourly rate' v-if='totalTime > 0'> -->
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(group, key) in grouppedTimeEntries'>
            <td class='removeGroup'><a href="#" v-on:click='removeGroup(key)'>&#10006;</a></td>
            <td class='user'>
              <img :src="group.user.profilePicture" :alt='group.user.name'>
              {{ group.user.name }}
            </td>
            <td>{{ group.duration | toHMS }}</td>
            <td>{{ (group.duration / totalTime * 100) | round }} %</td>
            <td>{{ Math.round((earnedSum * ( percent / 100 ) * Math.round(group.duration / totalTime * 10000) / 10000)) }} ({{ (earnedSum * ( percent / 100 ) * Math.round(group.duration / totalTime * 10000) / 10000) |  currency }}) </td>
            <td>
              <input type="number" v-model='group.salary'>
              {{ (group.duration / 3600) * (group.salary / 99) | currency }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><span v-if='totalTime > 0'>{{ earnedSum / totalTime * 3600 | round | currency  }}</span></td>
            <td><span v-if='totalTime > 0'>{{ Math.round(earnedSum * ( percent / 100 )) }} ({{ earnedSum * ( percent / 100 ) | round | currency }})</span></td>
          </tr>
        </tfoot>
      </table>

      <table class='customer-payments' v-if='customerPayments.length'>
        <tbody>
          <tr v-for='(payments, id) in grouppedCustomerPayments'>
            <td>{{ customers[id] | customerName }}</td>
            <td>
              <table class='customerPayment__table'>
                <tbody>
                  <tr v-for='payment in payments.items'>
                    <td><a v-bind:href="payment.id | flowluLink" target='_blank'>{{ payment.id }}</a></td>
                    <td>{{ payment.description}}</td>
                    <td>{{ payment.total }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan='2'></td>
                    <td>{{ payments.amount }}</td>
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default {
  name: 'app',
  data () {
    return {
      apiKey: '',
      flowluApiKey: '',
      workspaces: [],
      selectedWorkspace: null,

      startDate: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, 1)).toISOString().split('T')[0],
      endDate: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString().split('T')[0],

      users: [],

      activeProjects: [],
      timeEntries: [],
      grouppedTimeEntries: {},

      projects: {},
      earnedSum: null,
      loading: false,
      percent: 10,
      hourlyRate: 300,
      selectedClientId: null,

      customerPayments: [],
      customers: {}
    }
  },
  mounted() {
    if (localStorage.clockifyAPIKey) {
      this.apiKey = localStorage.clockifyAPIKey;
    }

    if (localStorage.flowluApiKey) {
      this.flowluApiKey = localStorage.flowluApiKey;
    }
  },
  methods: {
    submitKey: function(){
      let self = this
      self.workspaces = []
      self.selectedWorkspace = null
      self.users = []
      self.activeProjects = []
      self.timeEntries = []
      self.grouppedTimeEntries = {}
      self.earnedSum = null
      self.projects = {}
      self.loading = false
      self.selectedClientId = null

      self.customerPayments = []
      self.customers = {}

      self.startDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, 1)).toISOString().split('T')[0]
      self.endDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString().split('T')[0]

      axios
        .get('https://api.clockify.me/api/v1/workspaces/', {
          headers: {
            'content-type': 'application/json',
            'X-Api-Key': self.apiKey
          }
        })
        .then(function(response){
          localStorage.clockifyAPIKey = self.apiKey
          self.workspaces = response.data;
          if(self.workspaces.length == 1){
            self.selectedWorkspace = self.workspaces[0]
          }
        })
        .catch(function(error){
          alert(error.response.data.error || error.response.data.message || error.response.status)
        })
    },
    clearApiKey: function() {
      this.apiKey = ''
      if (localStorage.clockifyAPIKey) {
        localStorage.removeItem('clockifyAPIKey')
      }

      this.flowluApiKey = ''
      if (localStorage.flowluApiKey) {
        localStorage.removeItem('flowluAPIKey')
      }

      this.workspaces = []
      this.selectedWorkspace = null
      this.users = []
      this.activeProjects = []
      this.timeEntries = []
      this.grouppedTimeEntries = {}
      this.earnedSum = null
      this.projects = {}
      this.loading = false
      this.selectedClientId = null

      this.customerPayments = []
      this.customers = {}

      this.startDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, 1)).toISOString().split('T')[0]
      this.endDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString().split('T')[0]
    },
    selectProject: function(projectId) {
      this.selectedClientId = null
      if(this.activeProjects.indexOf(projectId) > -1 && this.activeProjects.length == 1)
        this.activeProjects = []
      else
        this.activeProjects = [projectId]
    },
    selectProjects: function(projectId) {
      this.selectedClientId = null
      if (this.activeProjects.indexOf(projectId) == -1)
        this.activeProjects.push(projectId)
      else
        this.activeProjects = this.activeProjects.filter(val => val != projectId )
    },
    selectClient: function(clientId) {
      if (this.selectedClientId == clientId) {
        this.selectedClientId = null
        this.activeProjects = []
      } else {
        this.selectedClientId = clientId
        this.activeProjects = Object.values(this.projects)
                                    .filter(project => project.clientId == clientId)
                                    .map(project => project.id)
      }
    },
    removeGroup: function(key) {
      Vue.delete(this.grouppedTimeEntries, key);
    },
    loadTimeEntries: function() {
      let self = this

      if (self.startDate && self.endDate && new Date(self.startDate) < new Date(self.endDate)) {
        self.loading = true

        let promises = []
        self.users.forEach((elm) => {
          promises.push(
            axios
              .get('https://api.clockify.me/api/v1/workspaces/' + self.selectedWorkspace.id + '/user/' + elm.id + '/time-entries', {
                headers: {
                  'content-type': 'application/json',
                  'X-Api-Key': self.apiKey
                },
                params: {
                  start: self.startDate + 'T00:00:00Z',
                  end: self.endDate + 'T00:00:00Z',
                  'page-size': 5000,
                  'project-required': 1,
                  'in-progress': 0
                }
              })
            )
        })

        Promise
          .all(promises)
          .then((values) => {
            self.timeEntries = [];

            values.forEach((item) => {
              self.timeEntries.push(item.data)
            })

            self.timeEntries = self.timeEntries.flat()

            self.loading = false
            if(self.activeProjects.length)
              self.loadDistribution()
          })
          .catch((response) => {
            alert(response.message)
            self.selectedWorkspace = null
            self.loading = false
          })
      } else {
        self.activeProjects = []
        self.timeEntries = []
        self.grouppedTimeEntries = {}
        self.earnedSum = null
        self.loading = false
        self.selectedClientId = null
      }
    },
    loadDistribution: function() {
      let self = this

      self.grouppedTimeEntries = {}
      self.earnedSum = null

      self.timeEntries
          .filter((timeEntry) => self.activeProjects.indexOf(timeEntry.projectId) > -1)
          .forEach((timeEntry) => {
            let userId = timeEntry.userId
            if(typeof self.grouppedTimeEntries[userId] === 'undefined'){
              self.$set(self.grouppedTimeEntries, userId, {
                'user': self.users.find((user) => user.id == userId),
                'duration': 0,
                'salary': 0
              });
            }
            let duration = timeEntry
                             .timeInterval
                             .duration
                             .match(/P((?<w>\d+)W)?((?<d>\d+)D)?T((?<h>\d+)H)?((?<m>\d+)M)?((?<s>\d+)S)?/)
                             .groups;

            let h = typeof duration.h === 'undefined' ? 0 : parseInt(duration.h);
            let m = typeof duration.m === 'undefined' ? 0 : parseInt(duration.m);
            let s = typeof duration.s === 'undefined' ? 0 : parseInt(duration.s);

            self.grouppedTimeEntries[timeEntry.userId].duration += s + m * 60 + h * 60 * 60;

          })
    },
    loadCustomerPayments: function() {
      let self = this
      self.customerPayments = []
      self.customers = {}
      axios
        .get('https://dsml.flowlu.ru/api/v1/module/fin/customer_payment/list', {
          headers: {
            'content-type': 'application/json',
          },
          params: {
            api_key: self.flowluApiKey,
            'filter[type]': 10,
            'filter[created_date]': {
              'start_date': self.startDate,
              'end_date': self.endDate
            }
          }
        })
        .then(function({ data }){
          localStorage.flowluApiKey = self.flowluApiKey
          self.customerPayments = data.response.items
          self.loadCustomers()
        })
        .catch(function(error){
          alert(error.response.data.error || error.response.data.message || error.response.status)
        })
    },
    loadCustomers: function() {
      let self = this
      axios
        .get('https://dsml.flowlu.ru/api/v1/module/crm/account/list', {
          headers: {
            'content-type': 'application/json',
          },
          params: {
            api_key: self.flowluApiKey,
            'filter[id]': self.customersIds
          }
        })
        .then(function(response){
          response.data.response.items.forEach((item) => (self.customers[item.id] = item))
        })
        .catch(function(error){
          alert(error.response.data.error || error.response.data.message || error.response.status)
        })
    },
    changeRange: function() {
      this.loadTimeEntries()
      this.loadCustomerPayments()
    }
  },
  filters: {
    toHMS: function(totalSeconds) {
      let s = totalSeconds;

      let m = ~~(s/60);
      s -= m * 60;

      let h = ~~(m/60);
      m -= h * 60;

      return h + ':' + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s;
    },
    round: function(value) {
      return Math.round(value * 100) / 100;
    },
    currency: function(value) {
      let dec = parseInt(value);
      let cents = parseInt(Math.round((value - parseInt(value)) * 100));
      return dec + '.' + (cents < 10 ? '0' : '') + cents;
    },
    flowluLink: function(id) {
      return 'https://dsml.flowlu.ru/_module/fin/view/new_create_payin/' + id
    },
    customerName: function(customer) {
      if (typeof customer == 'undefined') {
        return ''
      } else {
        return customer.name
      }
    }
  },
  computed: {
    orderedProjectIds: function() {
      return this.projectIds.sort((a, b) => this.projects[a].name[0].charCodeAt() - this.projects[b].name[0].charCodeAt());
    },
    grouppedCustomerPayments: function() {
      let groups = {}
      this.customerPayments.forEach((item) => {
        if ( typeof groups[item.crm_company_id] == 'undefined' ) {
          groups[item.crm_company_id] = {
            id: item.crm_company_id,
            amount: 0,
            items: []
          }
        }

        groups[item.crm_company_id].items.push(item)
        groups[item.crm_company_id].amount += item.total
      })

      return groups
    },
    customersIds: function() {
      if (!this.customerPayments.length) return []

      let ids = []
      this.customerPayments.forEach((item) => {
        if(ids.indexOf(item.crm_company_id) == -1) ids.push(item.crm_company_id)
      })

      return ids
    },
    totalTime: function() {
      let self = this
      return Object.keys(self.grouppedTimeEntries).reduce((a, cv) => {
        return a + self.grouppedTimeEntries[cv].duration
      }, 0)
    },
    projectIds: function() {
      return this.timeEntries.map((item) => item.projectId).filter((v, i, a) => a.indexOf(v) === i);
    },
    clients: function() {
      let self = this
      let tmpClients = {}
      self.projectIds.forEach((projectId) => {
        let project = self.projects[projectId]
        if (typeof project != 'undefined') {
          if(project.clientId)
            tmpClients[project.clientId] = project.clientName
        }

      })
      return tmpClients
    }
  },
  watch: {
    selectedWorkspace: function(workspace) {
      let self = this
      if (workspace) {
        axios
          .get('https://api.clockify.me/api/v1/workspaces/' + self.selectedWorkspace.id +  '/projects/?page-size=1000', {
            headers: {
              'content-type': 'application/json',
              'X-Api-Key': self.apiKey
            }
          })
          .then(function(response){
            self.projects = {}
            response.data.forEach((item) => {
              self.projects[item.id] = item
            })
          })
          .catch(function(error){
            alert(error.response.data.error || error.response.data.message || error.response.status)
          })

        axios
          .get('https://api.clockify.me/api/v1/workspaces/' + self.selectedWorkspace.id +  '/users', {
            headers: {
              'content-type': 'application/json',
              'X-Api-Key': self.apiKey
            }
          })
          .then(function(response) {
            self.users = response.data.filter((user) => {
              return user.memberships.some((membership) => {
                return membership.membershipType == 'WORKSPACE' && membership.membershipStatus == 'ACTIVE' && membership.targetId == self.selectedWorkspace.id
              })
            });

            self.loadTimeEntries();
          })
          .catch(function(error){
            alert(error.response.data.error || error.response.data.message || error.response.status)
          })

        self.loadCustomerPayments()
      }
    },
    activeProjects: {
      deep: true,
      handler: function() {
        this.loadDistribution()
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../node_modules/normalize.css/normalize.css';
  ul {
    padding: 0;
  }
  #app {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 30px 20px;
    font-size: 16px;
  }

  .workspaces {
    &__items {
      margin: 11px auto;
      padding: 0;
      max-width: 400px;
      display: flex;
    }
    &__item {
      display: flex;
      flex: 1 0 100px;
      flex-direction: row;
      border: 1px solid #c6d2d9;
      align-items: center;
      margin: 5px;
      cursor: pointer;
      height: 50px;
      color: #333;
      img {
        width: 50px;
        height: 50px;
      }
      &.selected {
        border-color: green;
        background: #bff3bf;
      }
    }
    &__item-title {
      margin: 0 auto;
    }
  }

  .projects-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    &__item {
      flex: 1 1;
      border: 1px solid #c6d2d9;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      white-space: nowrap;
      color: #333;
      cursor: pointer;
      max-width: 250px;
      span {
        font-size: 80%;
        &.dot {
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin-left: 7px;
        }
      }
      &.active {
        border-color: green;
        background: #bff3bf;
      }
    }
  }

  .clients-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__item {
      flex: 1 1;
      border: 1px solid #c6d2d9;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      white-space: nowrap;
      color: #333;
      cursor: pointer;
      max-width: 250px;
      &.active {
        border-color: green;
        background: #bff3bf;
      }
    }
  }

  .distribution__wrapper {
    overflow-y: scroll;
    display: flex;
    justify-content: space-around;
  }
  .distribution {
    // margin: 0 auto;
    width: 700px;
    thead {
      tr {
        height: 50px;
      }
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #eee;
        }
        &:hover {
          background-color: #ddd
        }
        td {
          padding: 5px;
        }
      }
    }
    tfoot {
      tr {
        height: 50px;
        td {
          padding: 5px;
        }
      }
    }
    input {
      text-align: center;
      font-size: 16px;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .user {
      display: flex;
      align-items: center;
    }
    .removeGroup {
      a {
        text-decoration: none;
        color: #2c3e50;
      }
    }
  }
  input[type="date"] {
    font-size: 16px;
    text-align: center;
  }
  input.apikey_input {
    font-size: 16px;
  }
  .clearlink {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    color: grey;
    opacity: .5;
    padding-top: 5px;
  }

  .customer-payments {
    tbody {
      tr {
        td {
          &:first-child {
            vertical-align: top;
          }
        }
      }
    }
    & > tbody > tr > td:first-child {
      line-height: 24px;
    }
  }

  .customerPayment {
    &__table {
      width: 100%;
      tfoot {
        font-weight: bold;
      }
      tbody {
        tr {
          td {
            &:first-child {
              width: 10%;
            }
            &:nth-child(2) {
              width: 50%;
            }
            &:nth-child(3) {
              width: 40%;
            }
          }
        }
      }

      a {
        color: black;
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    html {
      background: #141c23;
    }
    #app {
      color: #c6c8ca;
    }

    input {
      color: #c6c8ca;
      background-color: black;
      border: 1px solid #c6c8ca;
    }

    .clearlink {
      color: #eee;
    }

    .workspaces__item, .projects-list__item {
      background-color: #262626;
      color: #ccc;
      border-color: #545454;
      &.selected, &.active {
        background: #006100;
      }
    }

    .workspaces__item img {
      filter: invert(1);
    }

    .distribution {
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: #404040;
          }
          &:hover {
            background-color: #666
          }
        }
      }
      .removeGroup {
        a {
          color: #c6c8ca;
        }
      }
    }
  }
</style>
